a {
  color: $color-primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
p {
  margin: 10px 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $color-gray-light6;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-gray-light6;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $color-gray-light6;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $color-gray-light6;
}

// set margin for base layout

.page-main-wrapper {
  max-width: 1220px;
  margin: 0 auto;
}

.mid-container {
  max-width: 800px;
  margin: 0 auto;
}

.welcome-msg {
  font-size: 20px;
  font-weight: 700;
  color: $color-primary;
  margin-top: 20px;
}

// Titles

.main-page-title {
  word-break: break-word;
  h1 {
    color: $color-primary;
    font-size: 20px;
    margin-bottom: 0;
    margin-left: 5px;
  }
}

.sub-title-typ-1 {
  font-size: 14px;
  font-weight: 700;
  color: $color-primary;
}

.mbi-validity-dangerous {
  font-size: 12px;
  color: $color-red;
  font-weight: bold;
}

.mbi-validity-renewed {
  font-size: 12px;
  color: $color-gray-dark;
  font-weight: bold;
}

.mbi-validity-default {
  font-size: 12px;
  color: $color-green;
  font-weight: bold;
}

// Quick Flex classes

.txt-right {
  text-align: right;
}
.txt-left {
  text-align: left;
}
.txt-center {
  text-align: center;
}
.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.h-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.v-center {
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

header,
.row {
  display: flex; /* aligns all child elements (flex items) in a row */
}

.col {
  flex: 1; /* distributes space on the line equally among items */
}

// Common classes

.title-typ-1 {
  font-size: 18px;
  color: $color-body-font;
  font-weight: bold;
}

.width-100 {
  width: 100%;
}
.width-50 {
  flex-basis: 50%;
}
.width-25 {
  flex-basis: 25%;
}
.width-75 {
  flex-basis: 75%;
}

.width-50-d {
  width: 50%;
}

.width-75-d {
  width: 75%;
}

.card-border {
  border: 1px solid $color-gray-light3;
}

// Common Margin and Padding classes

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-5 {
  margin-left: 5px;
}
.ml-0 {
  margin-left: 0 !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.p-40 {
  padding: 40px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

// error-success messages
.success {
  color: $color-green;
  font-size: 12px;
  padding: 4px 0;
  font-weight: 400;
}

.error {
  color: $color-red;
  font-size: 12px;
  padding: 4px 0;
  font-weight: 400;
}
.asterisk {
  color: $color-red;
}
.error-msg {
  color: $color-red;
  font-size: 16px;
  font-weight: normal;
}
.error-bg {
  color: $color-red;
  font-size: 14px;
  font-weight: normal;
  background-color: #fdf4f4;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0;
}

.term-policy-parent {
  display: flex;
  align-items: center;
  a {
    font-size: 14px;
    display: flex;
    align-items: center;
    img {
      padding-right: 10px;
    }
    &:hover {
      text-decoration: none;
      color: $color-primary-light;
    }
  }
}
.tag-success {
  background-color: $color-green-2;
  padding: 4px 15px;
  color: $color-white;
  border-radius: 9px;
  margin: 0 5px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
}

.icon-link {
  padding-right: 20px;
  position: relative;
  // &::after{
  //   background: url('{{ get_asset_url("./images/info_icon.svg")}}');
  //   background-size: contain;
  //   width: 20px;
  //   height: 20px;
  //   position: absolute;
  //   content: '';
  //   display: inline-block;
  //   display: inline-block;
  //   top: -4px;
  //   right: 5px;
  // }
}
.body-font-color {
  color: $color-body-font !important;
}
.color-primary {
  color: $color-primary !important;
}
.width-inherit {
  width: inherit !important;
}

//icons
.icon-remove {
  // background: url('{{ get_asset_url("./images/grid_remove_icon.svg")}}');
  background: url("../images/grid_remove_icon.svg");
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

.icon-pdf-red {
  background: url("../images/pdf_icon_green.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: inline-block;
}

.icon-pdf-blue {
  background: url("../images/pdf_icon_blue.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: inline-block;
}

.icon-arrow-down {
  background: url("../images/arrow_down.svg");
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}
.icon-calender-icon {
  background: url("../images/calendar_icon.svg");
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}
.info-icon {
  background: url("../images/info_icon.svg") no-repeat;
  height: 17px;
  width: 17px;
  cursor: pointer;
}

.p-relative {
  position: relative;
}

.help-text {
  font-size: 12px;
  color: $color-primary;
}

.keyhelp-text {
  font-size: 13px;
  padding-right: 10px;
  color: #9e9e9e;
  font-weight: 700;
}

.help-text-2 {
  font-size: 14px;
  color: $color-body-font;
  a {
    text-decoration: underline;
  }
}
.required {
  color: $color-dark-red;
}
.small-txt {
  font-size: 12px;
}

.btn-nowarp {
  a {
    white-space: nowrap;
  }
}

.logout-wrapper {
  display: flex;
  justify-content: flex-end;
}

.page-title {
  h1 {
    color: #fff;
  }
}

@media screen and (max-width: 1680px) {
}

@media screen and (max-width: 1366px) {
  .page-main-wrapper {
    margin: 10px 0;
  }
  .body-container-wrapper {
    margin: 0 40px;
  }
}

@media screen and (max-width: 768px) {
  .page-main-wrapper {
    margin: 0 0;
    .alert {
      padding: 5px 10px;
      margin: 0;
      > div {
        font-size: 12px;
      }
    }
  }
  .body-container-wrapper {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 600px) {
  .mob-block {
    display: block;
  }
  .mob-btn-100 {
    width: 100%;
    margin: 5px 0;
    display: block;
    box-sizing: border-box;
  }
  .help-text-2 {
    margin: 0;
    font-size: 12px;
  }
  .footer-container {
    p {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
