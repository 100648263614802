// Button styles

@import "colors";

.btn-primary{
  background: $color-primary;
  padding: 10px 20px;
  color:$color-white;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 5px;
}

.btn-disable{
  background: $color-gray-light;
  padding: 10px 20px;
  color:$color-gray-light7;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  cursor: default;
  transition: all 0.3s ease-out;
  border-radius: 5px;
}

.btn-secondary{
    background: $color-white;
    padding: 10px 20px;
    color: $color-primary;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
    border-radius: 5px;
}
.btn-back{
  background: $color-gray-light;
  padding: 10px 20px;
  color: $color-primary;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 5px;
}

.btn-type1{
    background: $color-gray-light;
    padding: 10px 20px;
    color: $color-primary;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-out;
    border-radius: 5px;
}

.btn-secondary:focus, 
.btn-default:focus, 
.btn-primary:focus, 
.btn-back:focus,
.btn-type1:focus {
    outline: none !important;
}

button{
  border: none;
  box-shadow: none;
  max-height: 32px;
}

.btn-primary:hover {
    background: $color-primary-light !important;
    text-decoration: none;
}

.btn-default:hover, 
.btn-secondary:hover {
      background: $color-gray-light4 !important;
      text-decoration: none;
}
.btn-back:hover{
  text-decoration: none;
    background: $color-primary-light !important;
    color: $color-white;
}

.btn-type1:hover{
    text-decoration: none;
    background: $color-gray-light4 !important;
    color: $color-primary;
}

.btn-get-info{
    line-height: 27px;
}


.buttons-wrapper{
 .btn-back,
 .btn-primary{
   text-align: right;
 }
  .btn-revert{
      text-align: left;
    }
  .btn-back,
  .btn-primary{
      font-size: 16px;
      font-weight: 600;
      .btn-disable{
        font-size: 16px;
        font-weight: 600;
      }
  }
  button{
    max-height: inherit;
  }
  .btn-disable{
    font-size: 16px;
      font-weight: 600;
  }
}
.btn-lg{
  font-size: 16px;
      font-weight: 600;
      max-height: inherit;
}

.remove-mbi{
  background: transparent;
  border: $color-white;
  color: $color-primary !important;
  text-decoration: underline;
  font-weight:300;
  &:hover{
    background: $color-red !important;
    color: $color-white !important;
  }
}

.logout-btn {
  background: transparent;
  padding: 10px 20px;
  color:$color-primary;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    text-decoration: underline;
  }
}


@media screen and (max-width: 767px){
  .buttons-wrapper{
    justify-content: center;
  }
  .btn-get-info{
    display: block;
    margin-top: 10px;
  }
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .buttons-wrapper {
    margin: 20px 0;
    &:last-child {
      display: flex !important;
      >div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .checkout-links {
      display: block !important;
      margin-left: 0;
      p {
        font-size: 14px;
      }
    }
  }
}