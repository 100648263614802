body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-active{
  color: orange;
}
.main-link-item{
  margin-right: 3px;
}

.header-para{
  visibility: hidden;
  z-index: -100;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  transform: translate3d(0px, -239.2px, 0px);
  height: 240px;
  width: 1519px;
}
.header-slider{
  transform: translate3d(0px, -115.64px, 0px);
            position: absolute;
            height: 855px;
            width: 1519px;
            max-width: none;
}
.footer-rl1{
  background-image: url('https://www.autoconcept.se/hubfs/trustpilot.png');
                                  background-size: 100%;
                                  width: 75px;
                                  height: 75px;
                                  display: inline-block;
                                  cursor: pointer;
}
.footer-rl2{
  background-image: url('https://www.autoconcept.se/hubfs/facebook.png');
                                  background-size: 100%;
                                  width: 75px;
                                  height: 75px;
                                  display: inline-block;
                                  cursor: pointer;
}
.container-fluid{
    padding-right:0 !important;
     padding-left:0 !important;
}

.overlay-ext{
  position: relative !important;
}

.detail-pane{
  border-color: black;
  border-width: 1px;
  border-style: solid;
}
.content-ext{
  min-height: 222px !important;
}
.content-prod-ext{
  min-height: 357px !important;
}
.pop-body-ext{
  margin-bottom: 0px !important;
}
.mbi-card-ext{
  max-width: calc(100% / 3 - 5px) !important;
  margin-right: 5px !important;
}
.mbi-card-ext-p{
  max-width: calc(100% / 3 - 5px) !important;
  margin-right: 5px !important;
}
.border-choosen{
  border-top: 6px solid #70b3e2 !important;
  margin-top:-4px !important;
}
.border-selected{
  border-top: 6px solid #1caf0a !important;
  margin-top:-4px !important;
}
.popup-ext{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.pop-wrap-ext{
  margin-top:3px;
  padding-top: 32px !important;
  padding-right:17px !important
}
.f-left{
  float: left !important;
}
.react-datepicker-wrapper{
  max-width: 128px;
}
.ml-3{
  margin-left: 5px;
}
.btn-buy-nw{
  height:15px;
  margin-top:14px;
}
.low-height{
  min-height: 130px !important;
}

.remove-btn-ext{
  background-color: white !important;
}
.mb-5{
  margin-bottom: 5px !important;
}
.mt-5{
  margin-top: 5px !important;
}
 .stepper-wrapper li.active {
  cursor: default;
  pointer-events: none;
} 
.close-pop-ext{
  right: 16px !important;
  border-radius: unset !important;
  width: 8px !important;
}
.m-price-ext{	
  font-size: 12px !important;	
}

.select-drop {
  width: 100%;
  padding: 3px 8px !important;
}

.select-drop option{
  zoom: 1.1;
}

.product-panel{
  padding: 5px 10px !important;
}

.rm-icon{
  width:19px;
}
.mrgl-17{
  margin-left: 17px;
}
.content-section {
  padding-top: 20px;
  padding-bottom: 20px;
}
.visibility-hidden{
 visibility: hidden !important;
}
.display-none{
  display: none !important;
}
.mrgl-0{
  margin-left:0px !important;
}
.cursor-pointer{
  cursor: pointer;
}
.flexx {
  display: flex;
  flex-wrap: wrap;
  font-size: 54px;
  margin-top: 5%;
}

.line-break {
  width: 100%;
  text-align: right;
}
.a-back{
  font-size: 26px;
}
.page-title-wrapper {
  padding: 2px 0;
}
.content-section {
  padding: 0;
}
.position-relative {
  position: relative;
}
.parallax-section {
  background-image: url("https://www.autoconcept.se/hubfs/AdobeStock_316531884-1.jpeg");
  background-position: center;
  background-size: cover;
}
.hero-banner {
  max-height: 250px;
}
.centered, .row-fluid .span12.centered {
  padding-left: 20px;
  padding-right: 20px;
}
.menu-bar .hs-menu-wrapper ul ul {
  right: 0;
}

/* Small devices */
@media only screen and (max-width: 768px) {
  .body-container-wrapper {
    overflow: hidden;
  }
  .hero-banner {
    max-height: 100px;
  }
  .page-title-wrapper {
    padding: 0 20px;
  }
  .page-title-wrapper .row-fluid .span12.centered {
    padding: 0;
  }
  .page-title-wrapper .row-fluid .span12.centered h1 {
    font-size: 16px;
  }
  .row-fluid .span12.centered {
    padding-left: 20px;
    padding-right: 20px;
  }
  .content-section {
    padding-top: 0;
    padding-bottom: 10px;
  }
  p {
    margin-bottom: 10px;
  }
  .footer-container .white-content{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .footer-container .white-content .widget-span{
    padding-left: 0 !important;
  }
  .footer-container .white-content p {
    font-size: 12px;
    margin-bottom: 0;
  }
  .footer-rl1,
  .footer-rl2 {
    width: 50px;
    height: 50px;
  }
  .nav-header {
    padding: 5px 20px !important;
  }
  .page-title h1{
    padding: 2px 0;
     font-size: 14px !important;
  }
  .menu-bar .hs-menu-wrapper ul ul li a{
    padding: 5px;
  }
  .display-none-m {
    display: none !important;
  }
  .bottom-text {
    font-size: 12px;
  }
  .acc-body{
    margin-top: 3px;
    width: 337px;
  }
  .flexx {
    font-size: 24px;
  }
  .a-back{
    font-size: 18px;
  }
}