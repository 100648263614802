.m-l-cus-sup {
  margin-left: 5px;
}
.login-module,
.offer-module,
.profile-module,
.information-module,
.review-module,
.product-module,
.checkout-module,
.payment-accepted-module,
.payment-cancel-module,
.warranty-module,
.new-vehicle-module,
.keyprotect-registration-module,
.new-customer-flow,
.international-claim-module {
  font-family: Helvetica;
  font-size: 18px;
  color: #454545;
  line-height: initial;

  @import "colors";
  @import "typography";
  @import "stepper";
  @import "common";
  @import "buttons";
  @import "popup";
  @import "products";
  @import "insure-another";
  @import "login";
  @import "loading";
  @import "checkout";
  @import "forms";
  @import "new-form";
  @import "datepicker";
  @import "data-table";
  @import "owner-details";
  @import "review-order";
  @import "key-protect";
  @import "new-customer";
  @import "tooltip";
  @import "profile";
  @import "claim";
}
